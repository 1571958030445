import calciocomLogo from "@assets/images/calcio-logo-512.png"
import Link from "next/link"
import { Col, Row } from "react-bootstrap"
import Image from "next/image"
import React from "react"
import styles from "./style.module.scss"
import logo18 from "@assets/icons/footer/logo_18.png"
import logoADM from "@assets/icons/footer/logo_ADM.png"
import logoGiocoSicuro from "@assets/icons/footer/logo_giocosicuro.png"

export const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <Row className={styles.footerLinks}>
        <Col md={2} className={styles.footerSection}>
          <div className={styles.title}>
            <Link href={"/scommesse"}>
              <a>SCOMMESSE</a>
            </Link>
          </div>
          <Link href={"/scommesse/quote-pronostici"}>
            <a>Pronostici</a>
          </Link>
          <Link href={"/scommesse/quote-maggiorate"}>
            <a>Quote maggiorate</a>
          </Link>
          <Link href={"/scommesse/bonus"}>
            <a>Bonus</a>
          </Link>
          <Link href={"/scommesse/bookmakers"}>
            <a>Bookmaker</a>
          </Link>
          <Link href={"/scommesse/guide"}>
            <a>Guide</a>
          </Link>
        </Col>
        <Col md={2} className={styles.footerSection}>
          <div className={styles.title}>STATISTICHE</div>
          <Link href={"/campionato/italia-serie-a"}>
            <a>Serie A</a>
          </Link>
          <Link href={"/campionato/inghilterra-premier-league"}>
            <a>Premier League</a>
          </Link>
          <Link href={"/campionato/spagna-primera-division"}>
            <a>LaLiga</a>
          </Link>
          <Link href={"/campionato/francia-ligue-1"}>
            <a>Ligue 1</a>
          </Link>
          <Link href={"/campionato/germania-bundesliga"}>
            <a>Bundesliga</a>
          </Link>
        </Col>
        <Col md={2} className={styles.footerSection}>
          <div className={styles.title}>SQUADRE</div>
          <Link href={"/squadre/inter"}>
            <a>Inter</a>
          </Link>
          <Link href={"/squadre/juventus"}>
            <a>Juventus</a>
          </Link>
          <Link href={"/squadre/ac-milan"}>
            <a>Milan</a>
          </Link>
          <Link href={"/squadre/ssc-napoli"}>
            <a>Napoli</a>
          </Link>
          <Link href={"/squadre/acf-fiorentina"}>
            <a>Fiorentina</a>
          </Link>
          <Link href={"/squadre/as-roma"}>
            <a>Roma</a>
          </Link>
        </Col>
        <Col md={2} className={styles.footerSection}>
          <div className={styles.title}>GIOCATORI</div>
          <Link href={"/giocatori/ciro-immobile"}>
            <a>Ciro Immobile</a>
          </Link>
          <Link href={"/giocatori/theo-hernandez"}>
            <a>Theo Hernandez</a>
          </Link>
          <Link href={"/giocatori/federico-chiesa"}>
            <a>Federico Chiesa</a>
          </Link>
          <Link href={"/giocatori/dusan-vlahovic"}>
            <a>Dusan Vlahovic</a>
          </Link>
          <Link href={"/giocatori/romelu-lukaku"}>
            <a>Romelu Lukaku</a>
          </Link>
          <Link href={"/giocatori/victor-osimhen"}>
            <a>Victor Osimhen</a>
          </Link>
        </Col>
        <Col md={2} className={styles.footerSection}>
          <div className={styles.title}>CALCIO.COM</div>
          <Link href={"/privacy"}>
            <a>Privacy Policy</a>
          </Link>
          <Link href={"/cookie"}>
            <a>Cookie Policy</a>
          </Link>
          <Link href={"/terms-conditions"}>
            <a>Termini e condizioni</a>
          </Link>
        </Col>
        <Col md={2} className={styles.footerSection}>
          <div className={styles.title}>RESTA AGGIORNATO!</div>
          <div className={styles.links}>
            <Link href={"https://www.facebook.com/calciocom"}>
              <a>
                <Image
                  src={"https://s3.eu-central-1.wasabisys.com/calcio-com/social/facebook.png"}
                  alt="Facebook"
                  layout={"intrinsic"}
                  width={30}
                  height={30}
                />
              </a>
            </Link>
            <Link href={"https://www.instagram.com/calciocom_official"}>
              <a>
                <Image
                  src={"https://s3.eu-central-1.wasabisys.com/calcio-com/social/instagram.png"}
                  alt="Instagram"
                  layout={"intrinsic"}
                  width={30}
                  height={30}
                />
              </a>
            </Link>
            <Link href={"https://twitter.com/Calciocomoff"}>
              <a>
                <Image
                  src={"https://s3.eu-central-1.wasabisys.com/calcio-com/social/twitter.png"}
                  alt="Twitter"
                  layout={"intrinsic"}
                  width={30}
                  height={30}
                />
              </a>
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={styles.footerLogosContainerMobile}>
          <Row>
            <Col className={styles.footerText}>Gioca responsabilmente</Col>
          </Row>
          <Row>
            <div className={styles.footerLogos}>
              <Image src={logo18} alt="18" width={46} height={38} />
              <Image src={logoADM} alt="18" width={91} height={31} />
              <Image src={logoGiocoSicuro} alt="18" width={67} height={33} />
            </div>
          </Row>
        </Col>
        <Col lg={10}>
          <Row className={styles.footerText}>
            <p>
              Calcio.com è un innovativo data hub per football fanatics realizzato da Planet Entertainment, realtà del
              gruppo SKS365 dedicata ai servizi a valore aggiunto.
            </p>
            <p>
              Questo sito non rappresenta una testata giornalistica, in quanto viene aggiornato senza alcuna periodicità
            </p>
          </Row>
          <Row className={styles.calciocomLogo}>
            <Image src={calciocomLogo} alt="logo di calcio.com" />
          </Row>
          <Row className={styles.footerText}>
            <p>
              Planet Entertainment S.r.l., con sete legale in Roma, Via degli Aldobrandeschi 300, C.F. e P.IVA
              14877411000, iscritta al Reg. Imprese di Roma, n. REA RM-1552482
            </p>
            <p>
              {`© 2021${new Date().getFullYear() > 2021 ? `-${new Date().getFullYear()}` : ""} Calcio.com - `}
              <Link href="https://kama.sport">
                <a target="_blank" rel="noopener">
                  Powered By Kama.
                </a>
              </Link>
            </p>
          </Row>
        </Col>
        <Col className={styles.footerLogosContainer}>
          <Row>
            <Col className={styles.footerText}>Gioca responsabilmente</Col>
          </Row>
          <Row>
            <div className={styles.footerLogos}>
              <Image src={logo18} alt="18" width={46} height={38} />
              <Image src={logoADM} alt="18" width={91} height={31} />
              <Image src={logoGiocoSicuro} alt="18" width={67} height={33} />
            </div>
          </Row>
        </Col>
      </Row>
    </footer>
  )
}
