import React from "react"
import { GlobalSearchResults, GlobalSearchResultsProps } from "./GlobalSearchResults"
import { useSearch } from "./searchHook"
import { useRouter } from "next/router"
import moment from "moment"
import { orderBy } from "lodash"
import { LocalizedMatch } from "types/match"
import { MatchAPI } from "../../../apis/MatchAPI"
import { LocalizedTeam } from "types/team"
import * as ImageConstants from "../../../constants/images"
import { getMatchPageUrl } from "../../../utils/urls"
import Image from "next/image"

const TeamImage = ({ team }: { team: LocalizedTeam }) => {
  return (
    <div className="image-container icon">
      <Image
        src={team.image?.path || ImageConstants.TEAM_MISSING_IMAGE_PATH}
        alt={team.name}
        title={team.name}
        layout={"intrinsic"}
        width={35}
        height={35}
      />
    </div>
  )
}

const MatchesResult = ({ data, closeGlobalSearch }: { data: LocalizedMatch; closeGlobalSearch: () => void }) => {
  const router = useRouter()
  const { id, homeTeam, awayTeam, homeScore, awayScore, date } = data
  if (!homeTeam || !awayTeam) {
    return null
  }

  const matchUrl = getMatchPageUrl(data.calciocomSlug!)

  const open = () => {
    router.push(matchUrl)
    closeGlobalSearch()
  }

  return (
    <div className="global-search-match" onClick={open}>
      <div className="left-team">
        <TeamImage team={homeTeam} />
        <div className="name">{homeTeam?.name || ""}</div>
        <div className="score">{homeScore}</div>
      </div>
      <div className="score-separator">-</div>
      <div className="right-team">
        <TeamImage team={awayTeam} />
        <div className="name">{awayTeam?.name || ""}</div>
        <div className="score">{awayScore}</div>
      </div>
      <div className="date">{moment(date).format("DD/MM/YYYY")}</div>
    </div>
  )
}

function postprocessing(matches: (LocalizedMatch & { similarity: number })[]) {
  const now = moment()
  return orderBy(
    matches,
    [
      "similarity",
      (match) => {
        return Math.abs(moment(match.date).diff(now))
      },
    ],
    ["desc", "asc"],
  )
}

export const MatchesResults = ({
  search,
  ...props
}: {
  search: string
} & Omit<GlobalSearchResultsProps<LocalizedMatch>, "loading" | "data" | "Result">) => {
  const { loading, data } = useSearch(MatchAPI.search, search, "", postprocessing)

  return <GlobalSearchResults loading={loading} data={data} Result={MatchesResult} {...props} />
}
