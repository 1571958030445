import { calciocomApi, calciocomApiFrontend } from "./axiosConfig"
import { transformMatchEvents } from "./transform/matchEvents"
import * as APIConstants from "../constants/apis"
import { generatePath } from "react-router-dom"
import { LocalizedMatch, LocalizedMatchWithNews } from "../types/match"
import { Ids } from "../types/entity"
import { LocalizedMatchReferee } from "../types/matchReferee"
import { transformMatchStats } from "./transform/stats"
import { MatchTeamsStatsWithParameterFlat } from "../types/matchTeamStats"
import { LocalizedMatchEvent } from "../types/matchEvent"
import { LocalizedMatchTeamPlayers } from "../types/matchTeamPlayer"
import { createCancelTokenObject } from "./axiosUtils"
import { PaginationInput, PaginationOutput } from "../types/pagination"
import { LocalizedNews } from "types/news"

export type LocalizedMatchEventWithMessage = Omit<LocalizedMatchEvent, "type"> & {
  type: string
  message: string
}

export type DivergentStackedBarData = {
  code: string
  parameterName: string
  homePercentageValue: number
  awayPercentageValue: number
  homeOriginalValue: number
  awayOriginalValue: number
  unitMeasurement: string | null
}

export const MatchAPI = {
  getMatchIds: async function (calciocomSlug: string): Promise<Ids> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.MATCHES_V1_GET_IDS_BY_CALCIO_COM_SLUG, {
        calciocomSlug: calciocomSlug,
      }),
      method: APIConstants.GET,
    })
    return response.data.ids
  },
  getMatchByCalciocomSlug: async function (calciocomSlug: string): Promise<LocalizedMatch> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.MATCHES_V1_GET_BY_CALCIO_COM_SLUG, {
        calciocomSlug: calciocomSlug,
      }),
      method: APIConstants.GET,
    })
    return response.data.match
  },
  getMatch: async function (id: number): Promise<LocalizedMatch> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.MATCHES_V1_GET_BY_ID, {
        id: id,
      }),
      method: APIConstants.GET,
    })
    return response.data.match
  },
  getStat: async function (
    matchId: number,
    homeTeamId: number,
    awayTeamId: number,
  ): Promise<DivergentStackedBarData[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.MATCHES_V1_GET_STATS, {
        id: matchId,
      }),
      method: APIConstants.GET,
    })
    const matchStats: MatchTeamsStatsWithParameterFlat = response.data.stats

    return transformMatchStats(matchStats, homeTeamId, awayTeamId)
  },
  getMatchEvents: async function (matchId: number): Promise<LocalizedMatchEventWithMessage[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.MATCHES_V1_GET_EVENTS, {
        id: matchId,
      }),
      method: APIConstants.GET,
    })
    const matchEvents: LocalizedMatchEvent[] = response.data.matchEvents

    return transformMatchEvents(matchEvents)
  },
  getMatchTeamPlayers: async function (matchId: number): Promise<LocalizedMatchTeamPlayers> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.MATCHES_V1_GET_TEAM_PLAYERS, {
        id: matchId,
      }),
      method: APIConstants.GET,
    })

    return response.data.matchTeamPlayers
  },
  getRecentMatches: async function (
    competitionId?: number | null,
    seasonId?: number | null,
    turnId?: number | null,
    groupId?: number | null,
  ): Promise<LocalizedMatch[]> {
    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_V1_GET,
      params: {
        competitionId: competitionId,
        seasonId: seasonId,
        turnId: turnId,
        groupId: groupId,
      },
      method: APIConstants.GET,
    })
    return response.data.matches
  },
  getNextMatches: async function (
    competitionSeasonId: number,
    turnId: number,
    //groupId: number,
  ): Promise<LocalizedMatch[]> {
    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_NEXT_V1_GET,
      params: {
        competitionSeasonId: competitionSeasonId,
        turnId: turnId,
      },
      method: APIConstants.GET,
    })
    return response.data.matches
  },

  getHomeMatches: async function (groupId?: number | null, turnId?: number | null): Promise<LocalizedMatch[]> {
    const response = await calciocomApiFrontend.request({
      url: APIConstants.MATCHES_V1_GET_HOME,
      params: {
        groupId: groupId,
        turnId: turnId,
      },
      method: APIConstants.GET,
      cancelToken: cancelTokenObject[this.getHomeMatches.name].handleRequestCancellation().token,
    })

    return response.data.matches
  },

  getPlayedMatches: async function (
    competitionId?: number | null,
    seasonId?: number | null,
    turnId?: number | null,
  ): Promise<LocalizedMatch[]> {
    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_V1_GET,
      params: {
        competitionId: competitionId,
        seasonId: seasonId,
        turnId: turnId,
        onlyPlayed: true,
      },
      method: APIConstants.GET,
    })
    return response.data.matches
  },
  getByTeam: async function (competitionId: number, seasonId: number, teamId: number): Promise<LocalizedMatch[]> {
    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_V1_GET,
      params: {
        competitionId: competitionId,
        seasonId: seasonId,
        teamId: teamId,
      },
      method: APIConstants.GET,
    })
    return response.data.matches
  },
  getMatchesByDate: async function (date: string, onlyPlayed = true): Promise<LocalizedMatch[]> {
    const params: { date: string; onlyPlayed?: true | undefined } = { date: date }
    if (onlyPlayed) {
      params.onlyPlayed = onlyPlayed
    }
    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_V1_GET,
      params: params,
      method: APIConstants.GET,
    })

    return response.data.matches
  },
  getClosestDateWithMatches: async function (): Promise<string> {
    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_V1_GET_CLOSEST_DATE_WITH_MATCHES,
      method: APIConstants.GET,
    })
    return response.data.date
  },
  getNextDateWithMatches: async function (): Promise<string> {
    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_V1_GET_NEXT_DATE_WITH_MATCHES,
      method: APIConstants.GET,
    })
    return response.data.date
  },
  getMatchesCurrentlyLive: async function (): Promise<LocalizedMatch[]> {
    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_V1_GET,
      params: { onlyLive: true },
      method: APIConstants.GET,
    })
    return response.data.matches
  },
  getMatchReferees: async function (matchId: number): Promise<LocalizedMatchReferee[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.MATCHES_V1_GET_REFEREES, { id: matchId }),
      method: APIConstants.GET,
    })
    return response.data.referees
  },
  getHomeLiveMatches: async function (): Promise<LocalizedMatch[]> {
    const response = await calciocomApiFrontend.request({
      url: generatePath(APIConstants.MATCHES_V1_GET_HOME_LIVE_MATCHES),
      method: APIConstants.GET,
    })
    return response.data.liveMatches
  },
  getPublicMatchesByDate: async function (
    date: string,
    onlyLive = false,
    onlyPlayed = false,
    onlyFuture = false,
    cancel = true,
  ): Promise<LocalizedMatch[]> {
    const params: {
      date: string
      onlyLive: boolean | undefined
      onlyPlayed: boolean | undefined
      onlyFuture: boolean | undefined
    } = {
      date: date,
      onlyLive: onlyLive ? onlyLive : undefined,
      onlyPlayed: onlyPlayed ? onlyPlayed : undefined,
      onlyFuture: onlyFuture ? onlyFuture : undefined,
    }

    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_V1_GET,
      params: params,
      cancelToken: cancel
        ? cancelTokenObject[this.getPublicMatchesByDate.name].handleRequestCancellation().token
        : undefined,
    })

    return response.data.matches
  },
  getComparisonMatches: async function (teamId1: number, teamId2: number, order?: string): Promise<LocalizedMatch[]> {
    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_V1_GET,
      params: { teamId: teamId1, teamId2: teamId2, onlyPlayed: true, order: order },
      method: APIConstants.GET,
    })
    return response.data.matches
  },
  search: async function (search: string): Promise<(LocalizedMatch & { similarity: number })[]> {
    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_V1_SEARCH,
      method: APIConstants.GET,
      params: {
        search: search,
      },
    })
    return response.data.matches
  },
  getPredictionsPaginated: async function (
    paginationInput: PaginationInput,
    competitionId?: number,
  ): Promise<PaginationOutput<LocalizedMatchWithNews>> {
    const response = await calciocomApi.request({
      url: APIConstants.MATCHES_V1_POST_PREDICTIONS_GET_PAGINATED,
      method: APIConstants.POST,
      data: { ...paginationInput, competitionId: competitionId },
    })

    return response.data.matches
  },
  getPredictionsHome: async function (): Promise<PaginationOutput<LocalizedMatchWithNews>> {
    const response = await calciocomApiFrontend.request({
      url: APIConstants.MATCHES_V1_GET_PREDICTIONS_HOME,
      method: APIConstants.GET,
    })

    return response.data.matches
  },
  getPredictionByMatchId: async function (matchId: number): Promise<LocalizedNews | null> {
    try {
      const response = await calciocomApi.request({
        url: generatePath(APIConstants.MATCHES_V1_GET_PREDICTION_BY_MATCH_ID, { id: matchId }),
        method: APIConstants.GET,
      })

      return response.data
    } catch (error) {
      console.error(error)
      return null
    }
  },
}

const cancelTokenObject = createCancelTokenObject(MatchAPI)
