import React from "react"
import { GlobalSearchResults, GlobalSearchResultsProps } from "./GlobalSearchResults"
import { useSearch } from "./searchHook"
import { LocalizedTeam } from "types/team"
import { TeamAPI } from "../../../apis/TeamAPI"
import { getTeamPageUrl } from "../../../utils/urls"
import * as ImageConstants from "../../../constants/images"
import { useRouter } from "next/router"
import Image from "next/image"

const TeamResult = ({ data, closeGlobalSearch }: { data: LocalizedTeam; closeGlobalSearch: () => void }) => {
  const router = useRouter()
  const { id, name, country } = data

  const teamUrl = getTeamPageUrl(data.calciocomSlug!)

  const open = () => {
    router.push(teamUrl)
    closeGlobalSearch()
  }

  return (
    <div className="global-search-team" onClick={open}>
      <div className="image-container _team-image _team-image-home">
        <Image
          src={data.image?.path || ImageConstants.TEAM_MISSING_IMAGE_PATH}
          alt={data.name}
          title={data.name}
          layout={"intrinsic"}
          width={40}
          height={40}
        />
      </div>
      <div className="text-container">
        <span className="name">{name}</span>
        <span className="country">{country?.name || ""}</span>
      </div>
    </div>
  )
}

export const TeamResults = ({
  search,
  ...props
}: {
  search: string
} & Omit<GlobalSearchResultsProps<LocalizedTeam>, "loading" | "data" | "Result">) => {
  const { loading, data } = useSearch(TeamAPI.search, search)

  return <GlobalSearchResults loading={loading} data={data} Result={TeamResult} {...props} />
}
