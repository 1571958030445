import { SimplifiedNews } from "../types/news"

export const getNewsPageUrl = (news: SimplifiedNews) => {
  return `/news/${news.calciocomSlug}`
}

export const getGuidesPaginatedPageUrl = (pageNum: number): string => {
  if (pageNum === 1) {
    return `/scommesse/guide`
  }
  return `/scommesse/guide/${pageNum}`
}
export function redirectCheckUrl(slug: string) {
  return window.open("/goRedirect/" + slug, "_blank")
}
export function redirectPage(slug: string) {
  return "/goRedirect/" + slug
}
export function redirectPageQuota(id: string) {
  return "/goRedirectBonusQuota/" + id
}
export const getGuidesPageUrl = (guideCalciocomSlug: string) => {
  return `/scommesse/guide/${guideCalciocomSlug}`
}
export const getScommesseQuotePronosticiPageUrl = (competitionCalciocomSlug: string | null, page?: number) => {
  if (competitionCalciocomSlug) {
    if (page && page > 1) {
      return `/scommesse/quote-pronostici/${competitionCalciocomSlug}/${page}`
    }
    return `/scommesse/quote-pronostici/${competitionCalciocomSlug}`
  }
  if (page && page > 1) {
    return `/scommesse/quote-pronostici/${page}`
  }
  return `/scommesse/quote-pronostici`
}
export function getCompetitionCanonicalUrl(competitionCalciocomSlug: string) {
  return getCompetitionPageUrl(competitionCalciocomSlug)
}
export const getCompetitionPageUrl = (competitionCalciocomSlug: string, page = "generale") => {
  if (page === "generale") {
    return `/campionato/${competitionCalciocomSlug}`
  } else if (page === "pronostici-scommesse") {
    return `/scommesse/quote-pronostici/${competitionCalciocomSlug}`
  } else {
    return `/campionato/${competitionCalciocomSlug}/${page}`
  }
}

export const getCompetitionSeasonPageUrl = (
  competitionCalciocomSlug: string,
  seasonCalciocomSlug: string,
  page = "generale",
) => {
  if (page === "generale") {
    return `/campionato/${competitionCalciocomSlug}`
  } else {
    return `/campionato/${competitionCalciocomSlug}/stagione/${seasonCalciocomSlug}/${page}`
  }
}

export const getCompetitionSeasonGroupPageUrl = (
  competitionCalciocomSlug: string,
  seasonCalciocomSlug: string,
  groupCalciocomSlug: string,
  page = "generale",
) => {
  if (page === "generale") {
    return `/campionato/${competitionCalciocomSlug}`
  } else {
    return `/campionato/${competitionCalciocomSlug}/stagione/${seasonCalciocomSlug}/gruppo/${groupCalciocomSlug}/${page}`
  }
}

export const getCompetitionSeasonGroupTurnPageUrl = (
  competitionCalciocomSlug: string,
  seasonCalciocomSlug: string,
  groupCalciocomSlug?: string,
  turnCalciocomSlug?: string,
  page = "generale",
) => {
  if (page === "generale") {
    return `/campionato/${competitionCalciocomSlug}`
  } else if (page === "pronostici-scommesse") {
    return `/scommesse/quote-pronostici/${competitionCalciocomSlug}`
  } else {
    if (groupCalciocomSlug !== undefined && turnCalciocomSlug !== undefined) {
      return `/campionato/${competitionCalciocomSlug}/stagione/${seasonCalciocomSlug}/gruppo/${groupCalciocomSlug}/giornata/${turnCalciocomSlug}/${page}`
    } else {
      return getCompetitionSeasonPageUrl(competitionCalciocomSlug, seasonCalciocomSlug, page)
    }
  }
}

export const getCompetitionMenuPageUrl = (competitionCalciocomSlug: string, page = "generale") => {
  if (page === "generale") {
    return `/campionato/${competitionCalciocomSlug}`
  }
  return `/campionato/${competitionCalciocomSlug}/${page}`
}

export const getMatchPageUrl = (matchCalciocomSlug: string, page = "statistiche") => {
  return `/partite/${matchCalciocomSlug}/${page}`
}

export const getTeamPageUrl = (
  teamCalciocomSlug: string,
  competitionCalciocomSlug: string | null = null,
  seasonCalciocomSlug: string | null = null,
  page = "statistiche",
  otherProp: string | null = null,
): string => {
  let url = `/squadre/${teamCalciocomSlug}/`

  if ((page === "formazione" || page === "calendario") && (!competitionCalciocomSlug || !seasonCalciocomSlug)) {
    url += `${page}`
  } else {
    if (page !== "statistiche") {
      if (competitionCalciocomSlug && (page === "formazione" || page === "calendario" || page == "")) {
        url += `campionato/${competitionCalciocomSlug}/`
        if (seasonCalciocomSlug) {
          url += `stagione/${seasonCalciocomSlug}/${page}/`
        }
      } else {
        url += page
        if (otherProp) {
          url += "/" + otherProp
        }
      }
    }
  }
  return url.endsWith("/") ? url.slice(0, -1) : url
}

export const getPlayerPageUrl = (
  playerCalciocomSlug: string,
  page: string | null = null,
  other: string | null = null,
) => {
  if (page === "statistiche") {
    page = null
  }
  if (page) {
    if (page === "bilancio" && other) {
      return `/giocatori/${playerCalciocomSlug}/bilancio/${other}`
    }
    return `/giocatori/${playerCalciocomSlug}/${page}`
  }
  return `/giocatori/${playerCalciocomSlug}`
}

export const getMatchResultsPageUrl = (dateString: string) => {
  return `/risultati/${dateString}`
}

export const getBookmakersReviewUrl = (calciocomSlug: string) => {
  return `/scommesse/bookmakers/${calciocomSlug}`
}

export const getBonusQuotasPaginatedPageUrl = (pageNum: number): string => {
  if (pageNum === 1) {
    return `/scommesse/quote-maggiorate`
  }
  return `/scommesse/quote-maggiorate/${pageNum}`
}

export const removeLinkRelNoopenerNoreferrer = (testoHTML: string) => {
  return testoHTML.replace(/<a\b([^>]*)\brel=["']?noopener noreferrer["']?([^>]*)>/gi, "<a$1$2>")
}
