import { DivergentStackedBarData } from "../MatchAPI"
import { MatchTeamsStatsWithParameterFlat } from "../../types/matchTeamStats"

export function statNumberToString(value: number, unitMeasurement: string | null = null) {
  return value.toFixed(2).replace(".00", "") + (unitMeasurement || "")
}

export function transformMatchStats(
  matchStats: MatchTeamsStatsWithParameterFlat,
  homeTeamId: number,
  awayTeamId: number,
): DivergentStackedBarData[] {
  const homeTeam = matchStats.teams.find((t) => t.teamId === homeTeamId)
  const awayTeam = matchStats.teams.find((t) => t.teamId === awayTeamId)

  const divergentStackedBarDataList: DivergentStackedBarData[] = []

  homeTeam?.parameters.map((homeTeamParameter) => {
    const awayTeamParameter = awayTeam?.parameters.find((parameter) => parameter.code === homeTeamParameter.code)

    const homeTeamParameterValue = homeTeamParameter.value
    const awayTeamParameterValue = awayTeamParameter?.value
    if (homeTeamParameterValue !== undefined && awayTeamParameterValue !== undefined) {
      const den = homeTeamParameterValue + awayTeamParameterValue

      let homePercentageValue: number
      let awayPercentageValue: number

      if (den === 0) {
        homePercentageValue = 50
        awayPercentageValue = 50
      } else {
        homePercentageValue = (homeTeamParameterValue / den) * 100
        awayPercentageValue = (awayTeamParameterValue / den) * 100
      }

      if (awayTeamParameter) {
        divergentStackedBarDataList.push({
          code: homeTeamParameter.code,
          parameterName: homeTeamParameter.name,
          homePercentageValue: homePercentageValue,
          awayPercentageValue: awayPercentageValue,
          homeOriginalValue: homeTeamParameterValue,
          awayOriginalValue: awayTeamParameterValue,
          unitMeasurement: homeTeamParameter?.unitMeasurement,
        })
      }
    }
  })

  return divergentStackedBarDataList
}
