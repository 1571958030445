import { calciocomApi, calciocomApiFrontend } from "./axiosConfig"
import * as APIConstants from "../constants/apis"
import { Ids } from "../types/entity"
import { generatePath } from "react-router-dom"
import { LocalizedPlayer } from "../types/player"
import { LocalizedSeasonTeamPlayer } from "../types/seasonTeamPlayer"
import { PlayerIndexStatsWithParameterFlat } from "../types/playerIndexStats"
import { LocalizedCompetitionSeasonPlayerStanding } from "../types/competitionSeasonPlayerStanding"
import { CompetitionSeasonPlayerCompetitionSeasonParameterFlat } from "../types/competitionSeasonPlayerStats"
import { LocalizedPlayerTeamComparisonStats } from "types/playerTeamComparisonStats"
import { LocalizedPlayerRefereeComparisonStats } from "types/playerRefereeComparisonStats"
import { RetiredPlayerTypeParameterFlat } from "../types/retiredPlayerStats"
import { createCancelTokenObject } from "./axiosUtils"
import { CompetitionSeasonAPI } from "./CompetitionSeasonAPI"

export const PlayerAPI = {
  getPlayerIds: async function (calciocomSlug: string): Promise<Ids> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.PLAYERS_V1_GET_IDS_BY_CALCIO_COM_SLUG, {
        calciocomSlug: calciocomSlug,
      }),
      method: APIConstants.GET,
    })
    return response.data.ids
  },
  getPlayerByCalciocomSlug: async function (calciocomSlug: string): Promise<LocalizedPlayer> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.PLAYERS_V1_GET_BY_CALCIO_COM_SLUG, {
        calciocomSlug: calciocomSlug,
      }),
      method: APIConstants.GET,
    })
    return response.data.player
  },
  getPlayer: async function (id: number): Promise<LocalizedPlayer> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.PLAYERS_V1_GET_BY_ID, {
        id: id,
      }),
      method: APIConstants.GET,
    })
    return response.data.player
  },
  getPlayerTeams: async function (playerId: number): Promise<LocalizedSeasonTeamPlayer[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.PLAYERS_V1_GET_TEAMS_BY_ID, {
        id: playerId,
      }),
      method: APIConstants.GET,
    })
    return response.data.seasonTeamPlayers
  },
  getPlayerIndexStats: async function (playerId: number): Promise<PlayerIndexStatsWithParameterFlat> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.PLAYERS_V1_GET_INDEX_STATS, {
        id: playerId,
      }),
      method: APIConstants.GET,
    })
    return response.data.indexStats
  },
  getPlayerCompetitionSeasonStats: async function (
    playerId: number,
  ): Promise<CompetitionSeasonPlayerCompetitionSeasonParameterFlat[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.PLAYERS_V1_GET_COMPETITION_SEASON_STATS, {
        id: playerId,
      }),
      method: APIConstants.GET,
    })
    return response.data.stats.competitionSeasons
  },
  getRetiredStats: async function (playerId: number): Promise<RetiredPlayerTypeParameterFlat[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.PLAYERS_V1_GET_RETIRED_STATS, {
        id: playerId,
      }),
      method: APIConstants.GET,
    })
    return response.data.stats.types
  },
  getPlayerStandings: async function (
    competitionId: number,
    seasonId: number,
    limit = 5,
  ): Promise<LocalizedCompetitionSeasonPlayerStanding[]> {
    const response = await calciocomApi.request({
      url: APIConstants.PLAYERS_V1_GET_STANDINGS,
      params: {
        competitionId: competitionId,
        seasonId: seasonId,
        limit: limit,
      },
      method: APIConstants.GET,
    })
    return response.data.standings
  },
  getHomePlayerStandings: async function (
    competitionId: number,
    seasonId: number,
  ): Promise<LocalizedCompetitionSeasonPlayerStanding[]> {
    const response = await calciocomApiFrontend.request({
      url: APIConstants.PLAYERS_V1_GET_HOME_STANDINGS,
      params: {
        competitionId: competitionId,
        seasonId: seasonId,
        limit: 10,
      },
      method: APIConstants.GET,
      cancelToken: cancelTokenObject[this.getHomePlayerStandings.name].handleRequestCancellation().token,
    })
    return response.data.standings
  },
  getTeamComparisionStats: async function (playerId: number): Promise<LocalizedPlayerTeamComparisonStats> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.PLAYERS_V1_GET_TEAM_COMPARISON, {
        id: playerId,
      }),
      method: APIConstants.GET,
    })
    return response.data.stats
  },
  getRefereeComparisionStats: async function (playerId: number): Promise<LocalizedPlayerRefereeComparisonStats> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.PLAYERS_V1_GET_REFEREE_COMPARISON, {
        id: playerId,
      }),
      method: APIConstants.GET,
    })
    return response.data.stats
  },
  search: async function (search: string): Promise<LocalizedPlayer[]> {
    const response = await calciocomApiFrontend.request({
      url: APIConstants.PLAYERS_V1_SEARCH,
      method: APIConstants.GET,
      params: {
        search: search,
      },
    })
    return response.data.players
  },
}

const cancelTokenObject = createCancelTokenObject(PlayerAPI)
