import React, { useEffect, useState } from "react"
import { Layout, Spin } from "antd"
import { BackofficeSider } from "@components/PageLayout/Backoffice/BackofficeSider"
import { BackofficeLayoutConstants } from "constants/backofficeLayoutConstants"
import { BackofficeDefaultHeader } from "@components/PageLayout/Backoffice/BackofficeDefaultHeader"
import { BackofficeDefaultFooter } from "@components/PageLayout/Backoffice/BackofficeDefaultFooter"
import { AuthAPI } from "../../../../apis/AuthAPI"
import { Container } from "react-bootstrap"
import { LoginForm } from "@components/Login/LoginForm"
import { useRouter } from "next/router"

function BackofficeContainer({
  header,
  content,
  footer,
}: {
  header?: React.ReactNode
  content?: React.ReactNode
  footer?: React.ReactNode
}) {
  const router = useRouter()

  const [siderCollapsed, setSiderCollapsed] = useState<boolean>(false)
  const [isResponsive, setIsResponsive] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [isLogged, setIsLogged] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)

    AuthAPI.verifyToken()
      .then(() => {
        setIsLogged(true)
      })
      .catch(() => {
        setIsLogged(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return loading ? (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <Spin style={{ color: "white" }} tip="Loading..."></Spin>
    </div>
  ) : isLogged ? (
    <Layout className={"backoffice-layout"}>
      <BackofficeSider
        isResponsive={isResponsive}
        onCollapse={(collapsed, type) => {
          setSiderCollapsed(collapsed)

          let newIsResponsive = isResponsive
          if (type === "responsive") {
            newIsResponsive = !newIsResponsive
          }
          setIsResponsive(newIsResponsive)
        }}
      />
      <Layout className="site-layout">
        <div
          style={{
            paddingLeft: siderCollapsed
              ? BackofficeLayoutConstants.BACKOFFICE_COLLAPSED_SIDER_WIDTH
              : BackofficeLayoutConstants.BACKOFFICE_SIDER_WIDTH,
          }}
          className={"backoffice-header"}
        >
          {header || <BackofficeDefaultHeader />}
        </div>
        <div
          style={{
            marginLeft: siderCollapsed
              ? BackofficeLayoutConstants.BACKOFFICE_COLLAPSED_SIDER_WIDTH
              : BackofficeLayoutConstants.BACKOFFICE_SIDER_WIDTH,
          }}
          className={"backoffice-content-div"}
        >
          <div className={"backoffice-content-inner-div"}>{content}</div>
        </div>
        {footer || <BackofficeDefaultFooter />}
      </Layout>
    </Layout>
  ) : (
    <Container className={"login-container"}>
      <LoginForm onLoginCompleted={() => router.reload()} />
    </Container>
  )
}

export { BackofficeContainer }
