import React, { useEffect, useState } from "react"
import { Input, Card, Typography } from "antd"
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined"
import { PlayerResults } from "./PlayerResults"
import { TeamResults } from "./TeamResults"
import { MatchesResults } from "./MatchesResults"
import { NewsResults } from "./NewsResults"

const { Title } = Typography

export const GlobalSearchInput = ({ close, desktop }: { close?: () => void; desktop?: boolean }) => {
  const [search, setSearch] = useState("")
  const [focus, setFocus] = useState(false)
  const [closed, setClosed] = useState(true)

  const open = Boolean(focus && search)

  const onInputChange = (event: any) => {
    const search = event.target.value
    setSearch(search)
  }

  const onInputFocus = () => {
    setFocus(true)
  }

  const onClose = () => {
    setFocus(false)
    close && close()
  }

  useEffect(() => {
    if (open) {
      const onClick = (event: any) => {
        const target = event.target
        if (!target.closest(".global-search-input-container") || target.closest(".global-search-backdrop")) {
          setFocus(false)
          close && close()
        }
      }
      // eslint-disable-next-line no-undef
      document.addEventListener("click", onClick)
      // eslint-disable-next-line no-undef
      document.body.classList.add("disable-scroll")
      return () => {
        // eslint-disable-next-line no-undef
        document.removeEventListener("click", onClick)
        // eslint-disable-next-line no-undef
        document.body.classList.remove("disable-scroll")
      }
    }
  }, [open, setFocus])

  return (
    <div className={`global-search-input-container ${closed ? "closed" : ""} ${desktop ? " desktopContainer" : ""}`}>
      {open && <div className="global-search-backdrop"></div>}
      {!closed || !desktop ? (
        <Input
          value={search}
          onChange={onInputChange}
          onFocus={onInputFocus}
          className="global-search-input"
          size="large"
          placeholder={"Cerca una squadra, giocatore, news o partita"}
          suffix={<SearchOutlined />}
          onBlur={() => setClosed(true)}
        />
      ) : (
        <SearchOutlined className="global-search-icon" onClick={() => setClosed(false)} />
      )}
      {open && (
        <div className="global-search-results-container">
          <Card size="small" style={{ backgroundColor: "#f6f6f6" }}>
            <Card.Grid hoverable={false} className="global-search-results-grid global-search-results-grid--players">
              <Title level={4}>Giocatori</Title>
              <PlayerResults search={search} closeGlobalSearch={onClose} />
            </Card.Grid>
            <Card.Grid hoverable={false} className="global-search-results-grid global-search-results-grid--teams">
              <Title level={4}>Squadre</Title>
              <TeamResults search={search} closeGlobalSearch={onClose} />
            </Card.Grid>
            <Card.Grid hoverable={false} className="global-search-results-grid global-search-results-grid--liveMatches">
              <Title level={4}>Partite</Title>
              <MatchesResults search={search} closeGlobalSearch={onClose} />
            </Card.Grid>
            <Card.Grid hoverable={false} className="global-search-results-grid global-search-results-grid--news">
              <Title level={4}>News</Title>
              <NewsResults search={search} closeGlobalSearch={onClose} />
            </Card.Grid>
          </Card>
        </div>
      )}
    </div>
  )
}
