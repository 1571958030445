import { calciocomApiFrontend } from "./axiosConfig"
import * as APIConstants from "../constants/apis"
import { LoginInput } from "types/auth"
import { User } from "types/users"

export const AuthAPI = {
  login: async function (loginInput: LoginInput): Promise<User> {
    const response = await calciocomApiFrontend.request({
      method: APIConstants.POST,
      url: APIConstants.AUTH_V1_LOGIN,
      data: loginInput,
    })

    return response.data.user
  },
  verifyToken: async function () {
    await calciocomApiFrontend.request({
      url: APIConstants.AUTH_V1_VERIFY_TOKEN,
      method: APIConstants.GET,
    })
  },
}
