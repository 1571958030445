import { LocalizedMatchEventWithMessage } from "../MatchAPI"
import { LocalizedMatchEvent } from "../../types/matchEvent"
import { omit } from "lodash"

export function transformMatchEvents(matchEvents: LocalizedMatchEvent[]): LocalizedMatchEventWithMessage[] {
  const events: LocalizedMatchEventWithMessage[] = []

  matchEvents.forEach((matchEvent) => {
    const minute = matchEvent.minute

    const event: Omit<LocalizedMatchEventWithMessage, "message" | "type"> = {
      minute: minute,
      matchPeriod: matchEvent.matchPeriod,
      ...omit(matchEvent, "minute", "matchPeriod"),
    }
    switch (matchEvent.subtype) {
      case "substitute-in":
        events.push({
          ...event,
          type: "substitute-in",
          message: `${matchEvent.player?.name || matchEvent.playerName} (${matchEvent?.team?.name || ""})`,
        })
        break
      case "substitute-out":
        events.push({
          ...event,
          type: "substitute-out",
          message: `${matchEvent.player?.name || matchEvent.playerName} (${matchEvent?.team?.name || ""})`,
        })
        break

      case "yellow":
        events.push({
          ...event,
          type: "card yellow",
          message: `${matchEvent.player?.name || matchEvent.playerName} (${matchEvent?.team?.name || ""})`,
        })
        break

      case "yellow-red":
        events.push({
          ...event,
          type: "card yellow-red",
          message: `${matchEvent.player?.name || matchEvent.playerName} (${matchEvent?.team?.name || ""})`,
        })
        break

      case "red":
        events.push({
          ...event,
          type: "card red",
          message: `${matchEvent.player?.name || matchEvent.playerName} (${matchEvent?.team?.name || ""})`,
        })
        break

      case "goal":
        events.push({
          ...event,
          type: "goal",
          message: `${matchEvent.player?.name || matchEvent.playerName} (${matchEvent?.team?.name || ""})`,
        })
        break

      case "own-goal":
        events.push({
          ...event,
          type: "own-goal",
          message: `Autogol ${matchEvent.player?.name || matchEvent.playerName} (${matchEvent?.team?.name || ""})`,
        })
        break

      default:
        return
    }
  })

  return events.sort((a, b) => {
    if (a.matchPeriod < b.matchPeriod) {
      return -1
    }
    if (a.matchPeriod > b.matchPeriod) {
      return 1
    }
    if (a.minute < b.minute) {
      return -1
    }
    if (a.minute > b.minute) {
      return 1
    }
    return 1
  })
}
