import React, { useEffect, useState } from "react"
import { Button, Card, Input, Spin } from "antd"
import { Alert, Col, Row } from "react-bootstrap"
import * as emailValidator from "email-validator"
import { AuthAPI } from "../../../apis/AuthAPI"

export const LoginForm = ({ onLoginCompleted }: { onLoginCompleted: () => void }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  const [failed, setFailed] = useState<boolean>(false)

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter") {
        event.preventDefault()
        performLogin()
      }
    }
    if (document) {
      document.addEventListener("keydown", listener)

      return () => {
        document.removeEventListener("keydown", listener)
      }
    }
  }, [email, password])

  const performLogin = () => {
    if (!email || !password || !emailValidator.validate(email)) {
      setShowValidationErrors(true)

      return null
    } else {
      setLoading(true)

      AuthAPI.login({
        email: email.trim(),
        password: password,
      })
        .then(() => {
          onLoginCompleted()
        })
        .catch(() => {
          setFailed(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return null
  }

  return (
    <Card title={"Area Riservata"} className={"login-form"}>
      <Spin spinning={loading}>
        <Col>
          <Row>
            <Col>{failed && <Alert color={"danger"}>{"Email o Password errate!"}</Alert>}</Col>
          </Row>
          <Row className={"label-row"}>
            <Col>
              <span className={"label"}>Email</span>
            </Col>
          </Row>
          <Row className={"input-row"}>
            <Col>
              <Input
                placeholder={"Email"}
                value={email}
                onChange={(e) => {
                  setFailed(false)
                  setShowValidationErrors(false)
                  setEmail(e.target.value)
                }}
              />
              {showValidationErrors && !email && (
                <span style={{ color: "red", marginLeft: "10px" }}>{"* Campo obbligatorio"}</span>
              )}
              {showValidationErrors && email && !emailValidator.validate(email) && (
                <span style={{ color: "red", marginLeft: "10px" }}>{"Inserisci una email valida"}</span>
              )}
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>
              <span className={"label"}>Password</span>
            </Col>
          </Row>
          <Row className={"input-row"}>
            <Col>
              <Input.Password
                placeholder={"Password"}
                value={password}
                onChange={(e) => {
                  setFailed(false)
                  setShowValidationErrors(false)
                  setPassword(e.target.value)
                }}
              />
              {showValidationErrors && !password && (
                <span style={{ color: "red", marginLeft: "10px" }}>{"* Campo obbligatorio"}</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type={"primary"}
                className={"login-button"}
                onClick={() => {
                  performLogin()
                }}
              >
                Login
              </Button>
            </Col>
          </Row>
        </Col>
      </Spin>
    </Card>
  )
}
