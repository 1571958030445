import "../styles/globals.css"
import "../styles/calcio.com.css"
import "../styles/components.scss"
import "../styles/new-ui.scss"
import "../styles/pages.scss"
import "antd/dist/antd.css"
import "bootstrap/dist/css/bootstrap.min.css"
import type { AppProps } from "next/app"
import { PageLayout } from "@components/PageLayout/PageLayout"
import Router from "next/router"
import NProgress from "nprogress"
import "nprogress/nprogress.css" // styles of nprogress
import "../styles/global-search.scss"
import { BackofficePageLayout } from "@components/PageLayout/Backoffice/BackofficePageLayout"
import "../styles/backoffice.css"
import { ConfigProvider } from "antd"
import itIT from "antd/lib/locale/it_IT"
import { RSS, SEO } from "../src/types/next.js"
import { NavigationProps } from "@components/Navigation"
import { CalcioComHead } from "@components/PageLayout/CalcioComHead"
import React from "react"
import { CalcioComScript } from "@components/PageLayout/CalcioComScript"
import { SimplifiedBookmakerBonus } from "../src/types/bookmakerBonuses"

NProgress.configure({ showSpinner: false })

//Binding events for NProgress
Router.events.on("routeChangeStart", () => {
  NProgress.start()
})
Router.events.on("routeChangeComplete", () => {
  NProgress.done()
})
Router.events.on("routeChangeError", () => {
  NProgress.done()
})

function CalcioComApp({
  Component,
  pageProps,
}: AppProps<{
  pageLayoutWrapper?: boolean
  backofficeLayoutWrapper?: boolean
  rss?: RSS
  seo?: SEO
  title: string
  navigationProps?: NavigationProps
  newUI?: boolean
  showSearchInput?: boolean
  prev?: string
  next?: string
  withContainer?: boolean
  bookmakerBonuses?: SimplifiedBookmakerBonus[]
  showRightColumn?: boolean
  showCenterColumn?: boolean
  colDimensions?: { md: number; lg: number; xl: number }
}>) {
  const { pageLayoutWrapper = true, backofficeLayoutWrapper = false } = pageProps

  if (!pageLayoutWrapper) {
    return (
      <>
        <CalcioComHead
          seo={pageProps?.seo}
          rss={pageProps?.rss}
          title={pageProps?.title}
          prev={pageProps.prev}
          next={pageProps.next}
        />
        <CalcioComScript />
        <Component {...pageProps} />
      </>
    )
  }

  if (backofficeLayoutWrapper) {
    return (
      <BackofficePageLayout title={pageProps?.title}>
        <Component {...pageProps} />
      </BackofficePageLayout>
    )
  }

  return (
    <ConfigProvider locale={itIT}>
      <PageLayout
        title={pageProps?.title}
        seo={pageProps?.seo}
        rss={pageProps?.rss}
        navigationProps={pageProps?.navigationProps}
        newUI={pageProps.newUI}
        prev={pageProps.prev}
        next={pageProps.next}
        withContainer={pageProps.withContainer}
        bookmakerBonuses={pageProps.bookmakerBonuses}
        showRightColumn={pageProps?.showRightColumn}
        showCenterColumn={pageProps?.showCenterColumn}
        colDimensions={pageProps?.colDimensions}
      >
        <Component {...pageProps} />
      </PageLayout>
    </ConfigProvider>
  )
}
export default CalcioComApp
