import axios, { CancelTokenSource } from "axios"
const CancelToken = axios.CancelToken

export type CancelTokenObject = { [key: string]: { handleRequestCancellation: () => CancelTokenSource } }
export type CancelTokenInternalObject = { cancelToken: undefined | CancelTokenSource }

export function createCancelTokenObject(apiObject: { [key: string]: any }): CancelTokenObject {
  const cancelTokenObject: CancelTokenObject = {}

  Object.getOwnPropertyNames(apiObject).forEach((propertyName) => {
    const cancelTokenObjectValue: CancelTokenInternalObject = {
      cancelToken: undefined,
    }

    cancelTokenObject[propertyName] = {
      handleRequestCancellation: () => {
        if (cancelTokenObjectValue.cancelToken) {
          cancelTokenObjectValue.cancelToken.cancel(`${propertyName} cancel`)
        }

        cancelTokenObjectValue.cancelToken = CancelToken.source()

        return cancelTokenObjectValue.cancelToken
      },
    }
  })

  return cancelTokenObject
}
