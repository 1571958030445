import React from "react"
import { SimplifiedBookmakerBonus } from "types/bookmakerBonuses"
import style from "./BookmakersBanner.module.scss"
import { Row, Col } from "react-bootstrap"
import Image from "next/image"
import { TextButtonBonus } from "@components/UI/TextButtonBonus"
import starIcon from "@assets/icons/bookmakers/icon-star.png"
import Link from "next/link"

type Props = {
  bookmakerBonuses: SimplifiedBookmakerBonus[]
}

export default function BookmakersBanner({ bookmakerBonuses }: Props) {
  return (
    <div className={style.container}>
      <div className={style.list}>
        {bookmakerBonuses
          .filter((b) => b.showOnMobile)
          .slice(0, 3)
          .map((b) => {
            return (
              <div
                key={b.id}
                className={style.card}
                style={{
                  backgroundColor: b.backgroundColor || "white",
                  border: `1px solid ${b.backgroundColor || "#888"}`,
                }}
              >
                <Link passHref href={b.url!}>
                  <Row className={style.body}>
                    <Col md={5} className={style.image}>
                      <Image src={b.image!.path!} alt={b.name} width={120} height={60} />
                      <div className={style.stars}>
                        <Image width={20} height={20} src={starIcon} alt="star" />
                        <div
                          className={style.condition}
                          style={{
                            color: b.fontColor || "black",
                          }}
                        >
                          <span>{b.condition?.trim().split(" ").pop()}</span>
                        </div>
                      </div>
                    </Col>
                    <Col md={7} className={style.review}>
                      <div
                        className={style.text}
                        style={{
                          color: b.fontColor || "black",
                        }}
                      >
                        Bonus massimo
                        <br />
                        <span>{b.bonus}€</span> {b.extra ? <span className={style.extra}> + {b.extra}</span> : null}
                      </div>
                      <div className={style.button}>
                        <TextButtonBonus type="green" href={b.url!} text="Verifica" />
                      </div>
                    </Col>
                  </Row>
                </Link>
              </div>
            )
          })}
      </div>
    </div>
  )
}
