import { calciocomApi, calciocomApiFrontend } from "./axiosConfig"
import * as APIConstants from "constants/apis"
import { generatePath } from "react-router-dom"
import { LocalizedTeam, TeamPut, TeamsPaginatedInput } from "../types/team"
import { Ids } from "../types/entity"
import { LocalizedCompetitionSeason } from "../types/competitionSeason"
import { orderBy } from "lodash"
import { LocalizedSeasonTeamPlayer } from "../types/seasonTeamPlayer"
import { TeamIndexStatsWithParameterFlat } from "../types/teamIndexStats"
import { TeamBettingStatsWithParameterFlat } from "../types/teamBettingStats"
import { LocalizedMatch } from "../types/match"
import { CompetitionSeasonTeamCompetitionSeasonParameterFlat } from "../types/competitionSeasonTeamStats"
import { LocalizedTeamComparisonStats } from "types/teamComparisonStats"
import { PaginationOutput } from "types/pagination"

function teamToFormData(team: TeamPut) {
  const formData = new FormData()

  formData.append("data", JSON.stringify(team))

  return formData
}

export const TeamAPI = {
  getTeamIds: async function (calciocomSlug: string): Promise<Ids> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_IDS_BY_CALCIO_COM_SLUG, {
        calciocomSlug: calciocomSlug,
      }),
      method: APIConstants.GET,
    })
    return response.data.ids
  },
  getTeamByCalciocomSlug: async function (calciocomSlug: string): Promise<LocalizedTeam> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_BY_CALCIO_COM_SLUG, {
        calciocomSlug: calciocomSlug,
      }),
      method: APIConstants.GET,
    })
    return response.data.team
  },
  getTeam: async function (id: number): Promise<LocalizedTeam> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_BY_ID, {
        id: id,
      }),
      method: APIConstants.GET,
    })
    return response.data.team
  },
  getTeamPlayersBySeason: async function (id: number, seasonId: number): Promise<LocalizedSeasonTeamPlayer[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_PLAYERS_BY_ID, {
        id: id,
      }),
      params: {
        seasonId: seasonId,
      },
      method: APIConstants.GET,
    })
    return response.data.seasonTeamPlayers
  },
  getBettingStats: async function (
    id: number,
    competitionSeasonId: number | undefined,
  ): Promise<TeamBettingStatsWithParameterFlat> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_BETTING_STATS, {
        id: id,
      }),
      params: {
        competitionSeasonId: competitionSeasonId,
      },
      method: APIConstants.GET,
    })
    return response.data.bettingStats
  },
  getTeamCompetitionSeasonStats: async function (
    teamId: number,
  ): Promise<CompetitionSeasonTeamCompetitionSeasonParameterFlat[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_COMPETITION_SEASON_STATS, {
        id: teamId,
      }),
      method: APIConstants.GET,
    })
    return response.data.stats.competitionSeasons
  },
  getTeams: async function (competitionId: number | null, seasonId: number | null = null): Promise<LocalizedTeam[]> {
    const response = await calciocomApi.request({
      url: APIConstants.TEAMS_V1_GET,
      params: {
        competitionId: competitionId,
        seasonId: seasonId,
      },
      method: APIConstants.GET,
    })
    return response.data.teams
  },
  getTeamIndexStats: async function (playerId: number): Promise<TeamIndexStatsWithParameterFlat> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_INDEX_STATS, {
        id: playerId,
      }),
      method: APIConstants.GET,
    })
    return response.data.indexStats
  },
  getCompetitionSeasons: async function (id: number): Promise<LocalizedCompetitionSeason[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_COMPETITION_SEASONS, {
        id: id,
      }),
      method: APIConstants.GET,
    })
    return orderBy(response.data.competitionSeasons, ["start"], ["desc"])
  },
  getLastMatchesPlayed: async function (teamId: number, groupId: number): Promise<LocalizedMatch[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_LAST_MATCHES_PLAYED, {
        id: teamId,
      }),
      params: {
        groupId: groupId,
      },
      method: APIConstants.GET,
    })
    return response.data.matches
  },
  getComparisionStats: async function (teamId: number): Promise<LocalizedTeamComparisonStats> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_COMPARISON, {
        id: teamId,
      }),
      method: APIConstants.GET,
    })
    return response.data.stats
  },
  search: async function (search: string): Promise<LocalizedTeam[]> {
    const response = await calciocomApiFrontend.request({
      url: APIConstants.TEAMS_V1_SEARCH,
      params: {
        search: search,
      },
      method: APIConstants.GET,
    })
    return response.data.teams
  },
  getBackOfficePaginated: async function (
    paginationInput: TeamsPaginatedInput,
  ): Promise<PaginationOutput<LocalizedTeam>> {
    const response = await calciocomApiFrontend.request({
      method: APIConstants.POST,
      url: APIConstants.TEAMS_V1_POST_PAGINATED,
      data: paginationInput,
    })
    return response.data.teams
  },
  getCompetitionById: async function (id: number): Promise<LocalizedTeam> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_BY_ID_BACKOFFICE, {
        id: id,
      }),
      method: APIConstants.GET,
    })
    return response.data.team
  },
  update: async function (teamsPut: TeamPut): Promise<PaginationOutput<LocalizedTeam>> {
    const data = teamToFormData(teamsPut)
    const response = await calciocomApiFrontend.request({
      method: APIConstants.PUT,
      url: APIConstants.TEAMS_V1_PUT_UPDATE,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    })

    return response.data.teams
  },
  getTeamCompetitionSeasonPlayerStats: async function (teamId: number) {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_GET_COMPETITION_SEASON_PLAYERS_STATS, { id: teamId }),
      method: APIConstants.GET,
    })
    return response.data.stats
  },
  getTeamsSimplified: async function (
    competitionSeasonId: number | null,
    competitionId: number | null,
    seasonId: number | null = null,
  ): Promise<LocalizedTeam[]> {
    const response = await calciocomApiFrontend.request({
      url: APIConstants.TEAMS_V1_GET_SIMPLIFIED,
      params: {
        competitionSeasonId: competitionSeasonId,
        competitionId: competitionId,
        seasonId: seasonId,
      },
      method: APIConstants.GET,
    })
    return response.data.teams
  },
  getNextMatches: async function (teamId: number): Promise<LocalizedMatch[]> {
    const response = await calciocomApi.request({
      url: generatePath(APIConstants.TEAMS_V1_NEXT_MATCHES, { id: teamId }),
      method: APIConstants.GET,
      params: {
        limit: 5,
      },
    })
    return response.data.matches
  },
}
