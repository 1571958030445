import React, { useEffect, useMemo, useState } from "react"
import style from "./Filter.module.scss"
import { Row, Col } from "react-bootstrap"
import { CaretDownOutlined, CaretUpOutlined, FilterOutlined, LoadingOutlined } from "@ant-design/icons"
import { Select, Spin } from "antd"
import Link from "next/link"
import { NavigationProps } from "@components/Navigation"
import { CompetitionConstants } from "../../../constants/competitions"
import { nonNullable } from "next/dist/lib/non-nullable"
import { NavigationCompetition } from "../../../types/competition"
import { mapValues, orderBy } from "lodash"
import { getPriorityOrderCompetition, getPriorityOrderTeam } from "../../../utils/competition"
import { getCompetitionPageUrl, getPlayerPageUrl, getTeamPageUrl } from "../../../utils/urls"
import { LocalizedTeam, NavigationTeam } from "../../../types/team"
import { NavigationPlayer } from "../../../types/player"
import router from "next/router"
import { TeamAPI } from "../../../apis/TeamAPI"
import Image from "next/image"
const { Option } = Select

type Selection = {
  changed: boolean
  country: string | undefined | null
  competitionId: number | undefined | null
}

type Props = {
  navigationProps: NavigationProps
  mobile?: boolean
  closeFilterMenu?: () => void
}

function getPriorityOrderCountry(x: string) {
  let returnValue = 100

  if (x.startsWith("Italia")) {
    returnValue = 0
  }

  if (x.startsWith("Inghilterra")) {
    returnValue = 1
  }

  if (x.startsWith("Spagna")) {
    returnValue = 2
  }

  if (x.startsWith("Germania")) {
    returnValue = 3
  }

  if (x.startsWith("Francia")) {
    returnValue = 4
  }

  if (x.startsWith("Portogallo")) {
    returnValue = 5
  }

  if (x.startsWith("Paesi Bassi")) {
    returnValue = 6
  }

  if (x.startsWith("UEFA")) {
    returnValue = 7
  }

  if (x.startsWith("FIFA")) {
    returnValue = 8
  }

  if (x.startsWith(COUNTRY_SEPARATOR)) {
    returnValue = 9
  }

  return returnValue
}

function createSelection(
  competitionId: number | null | undefined,
  competitionsById: { [id: string]: NavigationCompetition },
): Selection {
  let country = null
  if (competitionId) {
    const comp = competitionsById[competitionId]
    if (comp) {
      country = comp.country!.name
    }
  }
  return {
    changed: false,
    country,
    competitionId,
  }
}
const COUNTRY_SEPARATOR = "----------------------------"

export default function FilterSideBar({ navigationProps, mobile, closeFilterMenu }: Props) {
  const [selectVisible, setSelectVisible] = useState<"country" | "competition" | "team" | "player" | null>(null)
  const [teams, setTeams] = useState<LocalizedTeam[]>([])
  const [championshipId, setCampionshipId] = useState<number | null | undefined>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const onCountrySelect = (newCountry: string) => {
    setSelectVisible("competition")
    if (newCountry && newCountry !== selection.country) {
      setSelection({
        changed: true,
        country: newCountry,
        competitionId: undefined,
      })
    }
  }

  useEffect(() => {
    if (championshipId) {
      setLoading(true)
      TeamAPI.getTeamsSimplified(null, championshipId, 153)
        .then((teams) => {
          setTeams(teams)
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [championshipId])

  const { countries, competitionsByCountry, competitionsById } = useMemo(() => {
    const countries: string[] = []
    const countryAdded: { [name: string]: boolean } = {}
    const competitionsByCountry: { [name: string]: NavigationCompetition[] } = {}
    const competitionsById: { [id: string]: NavigationCompetition } = {}

    navigationProps.competitions
      .filter((competition) => {
        return !!competition.country
      })
      .forEach((competition) => {
        const country = competition.country!.name

        if (!countryAdded[country]) {
          countries.push(country)
          countryAdded[country] = true
        }

        competitionsByCountry[country] = competitionsByCountry[country] || []
        competitionsByCountry[country].push(competition)
        competitionsById[competition.id] = competition
      })
    countries.push(COUNTRY_SEPARATOR)
    return {
      countries: orderBy(countries, [getPriorityOrderCountry, (x) => x]),
      competitionsByCountry: mapValues(competitionsByCountry, (competitions) =>
        orderBy(competitions, [getPriorityOrderCompetition, "name"]),
      ),
      competitionsById: competitionsById,
    }
  }, [navigationProps.competitions])
  const { championship, cup, international } = useMemo(() => {
    return {
      championship: CompetitionConstants.NAV_BAR_COMPETITIONS_IDS.championship
        .map((nav) => navigationProps.competitions.find((c) => c.id === nav))
        .filter(nonNullable),
      cup: CompetitionConstants.NAV_BAR_COMPETITIONS_IDS.cup
        .map((nav) => navigationProps.competitions.find((c) => c.id === nav))
        .filter(nonNullable),
      international: CompetitionConstants.NAV_BAR_COMPETITIONS_IDS.international
        .map((nav) => navigationProps.competitions.find((c) => c.id === nav))
        .filter(nonNullable),
    }
  }, [navigationProps.competitions])
  const [selection, setSelection] = useState<Selection>(() =>
    createSelection(navigationProps.selectedCompetitionId, competitionsById),
  )
  let competition: NavigationCompetition | undefined
  if (navigationProps.selectedCompetitionId && selection.country && competitionsByCountry[selection.country]) {
    competition = competitionsByCountry[selection.country].find(
      (competition) => competition.id === navigationProps.selectedCompetitionId,
    )!
  }
  let team: NavigationTeam | undefined
  if (navigationProps.selectedTeamId && navigationProps.teams) {
    team = navigationProps.teams.find((team) => team.id === navigationProps.selectedTeamId)!
  }

  let player: NavigationPlayer | undefined
  if (navigationProps.selectedPlayerId && navigationProps.players) {
    player = navigationProps.players.find((player) => player.id === navigationProps.selectedPlayerId)!
  }
  const redirect = (url: string) => {
    if (url && url.includes("/")) {
      close && close()
      router.push(url)
    }
  }
  const showCompetition = selection.country && competitionsByCountry[selection.country]
  const showTeam = showCompetition && !selection.changed && navigationProps.teams
  const showPlayers = showTeam && navigationProps.players

  const [openChampionship, setOpenChampionship] = useState<string | null>(null)

  const toggleChampionshipVisibility = (calciocomSlug: string) => {
    setOpenChampionship(openChampionship === calciocomSlug ? null : calciocomSlug)
  }

  return (
    <div className={`${style.container} ${mobile ? " filterMobileContainer" : ""} `}>
      <Row className={style.header}>
        <Col md={6} lg={2} className={style.headerIcon}>
          <FilterOutlined />
        </Col>
        <Col md={6} lg={10}>
          Filtra
        </Col>
      </Row>
      <Row className={style.selector}>
        <Select
          onSelect={onCountrySelect}
          value={selection.country}
          placeholder="Scegli paese"
          className="filterSelector"
        >
          {countries.map((country) => {
            return (
              <Option key={country} value={country} disabled={country === COUNTRY_SEPARATOR}>
                {country}
              </Option>
            )
          })}
        </Select>
        {showCompetition && (
          <Select
            className="filterSelector"
            onSelect={(value: string) => {
              mobile ? closeFilterMenu && closeFilterMenu() : undefined
              return redirect(value)
            }}
            value={
              navigationProps.selectedCompetitionId && competition
                ? getCompetitionPageUrl(competition.calciocomSlug!)
                : undefined
            }
            onDropdownVisibleChange={(open) => {
              if (!open && navigationProps.selectedCompetitionId) {
                setSelectVisible("team")
              }
            }}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            placeholder={"Scegli competizione"}
          >
            {((selection.country && competitionsByCountry[selection.country]) || []).map((competition, index) => {
              const url = getCompetitionPageUrl(competition.calciocomSlug!)
              return (
                <Option key={index} value={url}>
                  {competition.name}
                </Option>
              )
            })}
          </Select>
        )}
        {showTeam && (
          <Select
            className="filterSelector"
            onSelect={(value: string) => {
              mobile ? closeFilterMenu && closeFilterMenu() : undefined
              return redirect(value)
            }}
            placeholder={"Scegli squadra"}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            onDropdownVisibleChange={(open) => {
              if (!open && navigationProps.selectedTeamId) {
                setSelectVisible("player")
              }
            }}
            value={
              navigationProps.selectedTeamId && team && competition
                ? getTeamPageUrl(team.calciocomSlug!, competition.calciocomSlug || null)
                : undefined
            }
          >
            {orderBy(navigationProps.teams || [], [getPriorityOrderTeam, "name"]).map((team, index) => {
              const url = getTeamPageUrl(team.calciocomSlug!, (competition && competition.calciocomSlug) || null)
              return (
                <Option key={index} value={url}>
                  {team.name}
                </Option>
              )
            })}
          </Select>
        )}
        {showPlayers && (
          <Select
            className="filterSelector"
            onSelect={(value: string) => {
              mobile ? closeFilterMenu && closeFilterMenu() : undefined
              return redirect(value)
            }}
            placeholder={<span>Scegli giocatore</span>}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            value={navigationProps.selectedPlayerId && player ? getPlayerPageUrl(player.calciocomSlug!) : undefined}
          >
            {orderBy(navigationProps.players || [], ["name"]).map((player, index) => {
              const url = getPlayerPageUrl(player.calciocomSlug!)
              return (
                <Option key={index} value={url}>
                  {player.name}
                </Option>
              )
            })}
          </Select>
        )}
      </Row>
      <hr />
      <Row className={style.championships}>
        <div className={style.label}>Campionati in evidenza</div>
        {championship.map((c) => {
          return (
            <>
              <div className={style.championshipsRow} key={c.calciocomSlug}>
                {c.country?.image && (
                  <div className={style.image}>
                    <Image width={25} height={25} src={c.country?.image.path} alt={c.name} />
                  </div>
                )}
                <Link href={`/campionato/${c.calciocomSlug}`}>
                  <a onClick={() => (mobile ? closeFilterMenu && closeFilterMenu() : undefined)}>{c.name}</a>
                </Link>
                <div
                  className={style.downArrow}
                  onClick={() => {
                    setCampionshipId(c.id)
                    toggleChampionshipVisibility(c.calciocomSlug!)
                  }}
                >
                  {openChampionship === c.calciocomSlug ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </div>
              </div>
              {openChampionship === c.calciocomSlug && (
                <div className={`${style.teamsContainer} ${loading ? style.hidden : ""}`}>
                  {loading ? (
                    <Spin indicator={<LoadingOutlined />} />
                  ) : (
                    teams &&
                    teams.map((t) => (
                      <div className={style.team} key={t.id}>
                        <Link href={getTeamPageUrl(t.calciocomSlug!)} key={t.calciocomSlug!}>
                          <a onClick={() => (mobile ? closeFilterMenu && closeFilterMenu() : undefined)}>{t.name}</a>
                        </Link>
                      </div>
                    ))
                  )}
                </div>
              )}
            </>
          )
        })}
      </Row>
      <hr />
      <Row className={style.championships}>
        <div className={style.label}>Coppe europee</div>
        {cup.map((c) => {
          return (
            <Link href={`/campionato/${c.calciocomSlug}`} key={c.calciocomSlug}>
              <a onClick={() => (mobile ? closeFilterMenu && closeFilterMenu() : undefined)}>{c.name}</a>
            </Link>
          )
        })}
      </Row>
      <hr />
      <Row className={style.championships}>
        <div className={style.label}>Coppe Internazionali</div>
        {international.map((c) => {
          return (
            <Link href={`/campionato/${c.calciocomSlug}`} key={c.calciocomSlug}>
              <a onClick={() => (mobile ? closeFilterMenu && closeFilterMenu() : undefined)}>{c.name}</a>
            </Link>
          )
        })}
      </Row>
    </div>
  )
}
